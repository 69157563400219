export default function getAuthorProperties(primaryAuthor: {
  name: string
  profile_image?: string
  website?: string
  twitter?: string
  facebook?: string
}): {
  name?: string
  sameAsArray?: string
  image?: string
  facebookUrl?: string
} {
  let authorProfiles = []

  authorProfiles.push(
    primaryAuthor.website ? primaryAuthor.website : null,
    primaryAuthor.twitter
      ? `https://twitter.com/${primaryAuthor.twitter.replace(/^@/, ``)}/`
      : null,
    primaryAuthor.facebook
      ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, ``)}/`
      : null
  )

  authorProfiles = authorProfiles.filter((item) => Boolean(item))

  return {
    name: primaryAuthor.name,
    sameAsArray: authorProfiles.length
      ? `["${authorProfiles.join('", "')}"]`
      : undefined,
    image: primaryAuthor.profile_image,
    facebookUrl: primaryAuthor.facebook
      ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, ``)}/`
      : undefined
  }
}

// WAT
// getAuthorProperties.defaultProps = {
//   fetchAuthorData: false,
// }