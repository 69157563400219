import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import url from 'url'

import config from '../../../utils/siteConfig'
import ArticleMeta from './ArticleMeta'
import WebsiteMeta from './WebsiteMeta'
import AuthorMeta from './AuthorMeta'

interface MetaDataProps {
  data?: {
    ghostPost?: any
    ghostTag?: any
    ghostAuthor?: any
    ghostPage?: any
  }
  location: {
    pathname: string
  }
  title?: string
  description?: string
  image?: string
}

/**
 * MetaData will generate all relevant meta data information incl.
 * JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
 *
 */
function MetaData({
  data = {},
  settings: settingsQuery,
  title,
  description,
  image,
  location,
}: MetaDataProps & {
  settings: GatsbyTypes.GhostSettingsMetaDataQuery
}): JSX.Element {
  const canonical = url.resolve(config.siteUrl, location.pathname)
  const { ghostPost, ghostTag, ghostAuthor, ghostPage } = data
  const settings = settingsQuery.allGhostSettings.edges[0].node

  if (ghostPost) {
    return <ArticleMeta data={ghostPost} canonical={canonical} />
  } else if (ghostTag) {
    return <WebsiteMeta data={ghostTag} canonical={canonical} type="Series" />
  } else if (ghostAuthor) {
    return <AuthorMeta data={ghostAuthor} canonical={canonical} />
  } else if (ghostPage) {
    return <WebsiteMeta data={ghostPage} canonical={canonical} type="WebSite" />
  } else {
    title = title || config.siteTitleMeta || settings.title
    description =
      description || config.siteDescriptionMeta || settings.description
    image = image || settings.cover_image || undefined

    image = image ? url.resolve(config.siteUrl, image) : undefined

    return (
      <WebsiteMeta
        data={{}}
        canonical={canonical}
        title={title}
        description={description}
        image={image}
        type="WebSite"
      />
    )
  }
}

const MetaDataQuery = (props: MetaDataProps) => (
  <StaticQuery<GatsbyTypes.GhostSettingsMetaDataQuery>
    query={graphql`
      query GhostSettingsMetaData {
        allGhostSettings {
          edges {
            node {
              title
              description
              cover_image
            }
          }
        }
      }
    `}
    render={(data) => <MetaData settings={data} {...props} />}
  />
)

export default MetaDataQuery
