import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import ImageMeta from './ImageMeta'
import getAuthorProperties from './getAuthorProperties'
import config from '../../../utils/siteConfig'

interface AuthorMetaProps {
  data: {
    name: string
    bio?: string
    profile_image?: string
    website?: string
    twitter?: string
    facebook?: string
  }
  canonical: string
}

function AuthorMeta({
  data,
  settings: settingsData,
  canonical,
}: AuthorMetaProps & {
  settings: GatsbyTypes.GhostSettingsAuthorMetaQuery
}) {
  const settings = settingsData.allGhostSettings.edges[0].node

  const author = getAuthorProperties(data)
  const shareImage = author.image || settings.cover_image || undefined
  const title = `${data.name} - ${settings.title}`
  const description =
    data.bio || config.siteDescriptionMeta || settings.description

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(
              /^@/,
              ``
            )}/`}
          />
        )}
        {settings.twitter && (
          <meta name="twitter:creator" content={settings.twitter} />
        )}
        <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Person",
                        "name": "${data.name}",
                        ${
                          author.sameAsArray
                            ? `"sameAs": ${author.sameAsArray},`
                            : ``
                        }
                        "url": "${canonical}",
                        ${
                          shareImage
                            ? `"image": {
                                "@type": "ImageObject",
                                "url": "${shareImage}",
                                "width": "${config.shareImageWidth}",
                                "height": "${config.shareImageHeight}"
                            },`
                            : ``
                        }
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${config.siteUrl}"
                        },
                        "description": "${description}"
                    }
                `}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  )
}

const AuthorMetaQuery = (props: AuthorMetaProps) => (
  <StaticQuery<GatsbyTypes.GhostSettingsAuthorMetaQuery>
    query={graphql`
      query GhostSettingsAuthorMeta {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
      }
    `}
    render={(data) => <AuthorMeta settings={data} {...props} />}
  />
)

export default AuthorMetaQuery
