import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import url from 'url'

import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'

interface WebsiteMetaProps {
  data: {
    title?: string
    meta_title?: string
    meta_description?: string
    name?: string
    feature_image?: string
    description?: string
    bio?: string
    profile_image?: string
  }
  canonical: string
  title?: string
  description?: string
  image?: string
  type: 'WebSite' | 'Series'
}

function WebsiteMeta ({
  data,
  settings: settingsData,
  canonical,
  title,
  description,
  image,
  type,
}: WebsiteMetaProps & {
  settings: GatsbyTypes.GhostSettingsWebsiteMetaQuery
}) {
  const settings = settingsData.allGhostSettings.edges[0].node

  const publisherLogo = url.resolve(
    config.siteUrl,
    settings.logo || config.siteIcon
  )
  let shareImage =
    image || data.feature_image || settings.cover_image || undefined

  shareImage = shareImage ? url.resolve(config.siteUrl, shareImage) : undefined

  description =
    description ||
    data.meta_description ||
    data.description ||
    config.siteDescriptionMeta ||
    settings.description
  title = `${title || data.meta_title || data.name || data.title} - ${
    settings.title
  }`

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(
              /^@/,
              ``
            )}/`}
          />
        )}
        {settings.twitter && (
          <meta name="twitter:creator" content={settings.twitter} />
        )}
        <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "${type}",
                        "url": "${canonical}",
                        ${
                          shareImage
                            ? `"image": {
                                "@type": "ImageObject",
                                "url": "${shareImage}",
                                "width": "${config.shareImageWidth}",
                                "height": "${config.shareImageHeight}"
                            },`
                            : ``
                        }
                        "publisher": {
                            "@type": "Organization",
                            "name": "${settings.title}",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${publisherLogo}",
                                "width": 60,
                                "height": 60
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${config.siteUrl}"
                        },
                        "description": "${description}"
                    }
                `}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  )
}

const WebsiteMetaQuery = (props: WebsiteMetaProps) => (
  <StaticQuery<GatsbyTypes.GhostSettingsWebsiteMetaQuery>
    query={graphql`
      query GhostSettingsWebsiteMeta {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
      }
    `}
    render={(data) => <WebsiteMeta settings={data} {...props} />}
  />
)

export default WebsiteMetaQuery
